@tailwind base;
@tailwind components;
@tailwind utilities;

/*This is introduced specifically for the Booking Management calendar component, 
where once the dialog is open a click outside actually triggers another selection*/
[vaul-overlay][vaul-snap-points-overlay='true']:not([vaul-drawer-visible='false']) {
  pointer-events: none !important;
}
@layer base {
  :root {
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: rgb(203 213 225); /* slate-300 */
    border-radius: 6px;
    border: 3px solid white;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgb(148 163 184); /* slate-400 */
  }

  .dark .scrollbar::-webkit-scrollbar-thumb {
    background-color: rgb(71 85 105); /* slate-600 */
    border-color: rgb(2 6 23); /* slate-950 */
  }

  .dark .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgb(100 116 139); /* slate-500 */
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes pulseSize {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-spin {
  animation: spin 1.5s linear infinite;
}

.animate-pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-pulse-size {
  animation: pulseSize 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
